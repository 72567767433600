import type { IColumn } from '../../types'

export default (): IColumn[] => [
  {
    dataFieldName: 'loading',
    displayName: 'Loading',
    dataType: 'string',
    order: 1,
  },
  {
    dataFieldName: 'loading2',
    displayName: 'Loading',
    dataType: 'string',
    order: 2,
  },
  {
    dataFieldName: 'loading3',
    displayName: 'Loading',
    dataType: 'string',
    order: 3,
  },
  {
    dataFieldName: 'loading4',
    displayName: 'Loading',
    dataType: 'string',
    order: 4,
  },
  {
    dataFieldName: 'loading5',
    displayName: 'Loading',
    dataType: 'string',
    order: 5,
  },
  {
    dataFieldName: 'loading6',
    displayName: 'Loading',
    dataType: 'string',
    order: 6,
  },
  {
    dataFieldName: 'loading7',
    displayName: 'Loading',
    dataType: 'string',
    order: 7,
  },
  {
    dataFieldName: 'loading8',
    displayName: 'Loading',
    dataType: 'string',
    order: 8,
  },
  {
    dataFieldName: 'loading9',
    displayName: 'Loading',
    dataType: 'string',
    order: 9,
  },
]
